// import React from "react";
// import "./Navbar.css"; // Assuming you will use this CSS file for styling

// function Navbar() {
//   return (
//     <nav className="navbar">
//       <div className="navbar-logo">GFT</div>
//       <ul className="navbar-links">
//         <li>
//           <a href="#home">Home</a>
//         </li>
//         <li>
//           <a href="#about">About Us</a>
//         </li>
//       </ul>
//     </nav>
//   );
// }

// export default Navbar;

import React, { useState } from "react";
import "./Navbar.css"; // Assuming you will use this CSS file for styling
import { Link, useNavigate } from "react-router-dom";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false); // State to toggle the menu
  const navigate = useNavigate();

  const handleToggle = () => {
    setIsOpen(!isOpen); // Toggle menu open/close state
  };

  const onLogoClick = () => {
    navigate("/");
  };

  return (
    <nav className="navbar">
      <div
        className="navbar-logo"
        onClick={() => {
          onLogoClick();
        }}
      >
        GFT
      </div>
      <button className="navbar-toggle" onClick={handleToggle}>
        {/* Hamburger icon */}
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </button>
      <ul className={`navbar-links ${isOpen ? "open" : ""}`}>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/jobs-ai">Jobs AI</Link>
        </li>
        <li>
          <Link to="/prop-ai">Prop AI</Link>
        </li>
        <li>
          <Link to="/emerging-tech">Emerging Tech</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;

import React, { useEffect, useRef } from "react";
import "./Home.css";
import videoBg from "../../../Assets/home-section.mp4";
import Navbar from "../../Navbar/Navbar";
import aiIcon from "../../../Assets/section-two.png"; // AI icon image
import aiLargeIcon from "../../../Assets/icon-three.png";
import iconOne from "../../../Assets/icon-one.png";
import iconTwo from "../../../Assets/icon-two.png";
import { Link, useNavigate } from "react-router-dom";

function Home() {
  const typewriterRef = useRef(null);
  const navigate = useNavigate();

  const goToJobAI = () => {
    navigate("/jobs-ai");
  };

  const goToPropAI = () => {
    navigate("/prop-ai");
  };

  const goToEmerging = () => {
    navigate("/emerging-tech");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("typewriter-active");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (typewriterRef.current) {
      observer.observe(typewriterRef.current);
    }

    return () => {
      if (typewriterRef.current) {
        observer.unobserve(typewriterRef.current);
      }
    };
  }, []);

  return (
    <>
      <Navbar />
      <div className="home-container">
        <div className="video-background">
          <video autoPlay muted loop playsInline id="bg-video">
            <source src={videoBg} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="content-overlay">
          <h1 className="title">GREAT FUTURE TECH</h1>
          <p className="subtitle">
            Innovating the Future with AI & Emerging Technologies
          </p>
        </div>
      </div>

      {/* New Section */}
      <section className="about-section">
        <div className="about-text">
          <div className="title-text-type">
            <h2 ref={typewriterRef} className="about-title">
              Great Future Tech, a UK-based startup,
            </h2>
          </div>
          <div className="about-para-text">
            <p className="about-paragraph">
              We are focused on delivering innovative solutions that help
              businesses adapt and thrive. Specializing in AI-powered
              recruitment and property solutions, we also hold patents for
              cutting-edge biochip and drone technologies. Our expert team,
              consisting of PhD holders across various fields, ensures that our
              solutions are both advanced and practical.
            </p>
          </div>
        </div>
        <div className="about-icon">
          <img src={aiIcon} alt="AI Icon" className="ai-image" />
        </div>
      </section>

      {/* Parent Solutions Section */}
      <section className="parent-solutions">
        <h2 className="section-heading">Present Solutions</h2>
        <div className="card-container">
          <div
            className="book"
            onClick={() => {
              goToJobAI();
            }}
          >
            <div className="inner-text">
              <p>AI-Powered Recruitment Solutions</p>
            </div>
            <div className="cover">
              <img src={iconTwo} alt="AI Icon" />
            </div>
          </div>
          <div
            className="book"
            onClick={() => {
              goToPropAI();
            }}
          >
            <div className="inner-text">
              <p>AI for Property Business</p>
            </div>
            <div className="cover">
              <img src={iconOne} alt="AI Icon" />
            </div>
          </div>
        </div>
      </section>

      {/* Emerging Technologies Section */}
      <section className="emerging-tech-section">
        <div className="emerging-tech-container">
          <img src={aiLargeIcon} alt="AI Icon" className="emerging-tech-icon" />
          <div className="emerging-tech-text">
            <h2>Emerging Technologies</h2>
            <p className="tech-subheading">
              Exploring the Next Frontier in Technology
            </p>
            <p className="tech-paragraph">
              Shaping the future with groundbreaking innovations in biochips,
              autonomous vehicle control, quantum computing, and cognitive
              systems.
            </p>
            <button
              className="tech-button"
              onClick={() => {
                goToEmerging();
              }}
            >
              Learn More
            </button>
          </div>
        </div>
      </section>
      <section className="our-team-section">
        <h2 className="our-team-heading">Our Team</h2>
        <p className="our-team-text">
          Our solutions are built by a talented team of PhD experts in AI,
          engineering, bioengineering, and emerging tech, ensuring that we
          remain at the cutting edge of innovation.
        </p>
      </section>
      <footer className="footer">
        <div className="footer-content">
          <p className="company-name">Great Future Tech</p>
          <ul className="footer-links">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/jobs-ai">Jobs AI</Link>
            </li>
            <li>
              <Link to="/prop-ai">Prop AI</Link>
            </li>
            <li>
              <Link to="/emerging-tech">Emerging Tech</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
          <div className="footer-divider"></div>
          <p className="footer-copyright">
            &copy; 2024 Great Future Tech. All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
}

export default Home;

// import React, { useEffect, useRef } from "react";
// import "./Home.css";
// import videoBg from "../../../Assets/home-section.mp4";
// import Navbar from "../../Navbar/Navbar";
// import aiIcon from "../../../Assets/section-two.png"; // AI icon image

// function Home() {
//   const line1Ref = useRef(null);
//   const line2Ref = useRef(null);

//   useEffect(() => {
//     // Start typing the first line
//     setTimeout(() => {
//       if (line1Ref.current) {
//         line1Ref.current.classList.add("typewriter-active");
//       }
//     }, 500); // Line 1 starts after 0.5s

//     // Start typing the second line after the first one completes
//     setTimeout(() => {
//       if (line2Ref.current) {
//         line2Ref.current.classList.add("typewriter-active");
//       }
//     }, 4000); // Line 2 starts after 4s (adjust based on how long line 1 takes)
//   }, []);

//   return (
//     <>
//       <Navbar />
//       <div className="home-container">
//         <div className="video-background">
//           <video autoPlay muted loop playsInline id="bg-video">
//             <source src={videoBg} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         </div>
//         <div className="content-overlay">
//           <h1 className="title">GREAT FUTURE TECH</h1>
//           <p className="subtitle">
//             Innovating the Future with AI & Emerging Technologies
//           </p>
//         </div>
//       </div>

//       {/* New Section */}
//       <section className="about-section">
//         <div className="about-text">
//           <h2 className="about-title">
//             <span ref={line1Ref} className="typewriter-line">
//               At Great Future Tech, a UK-based startup,
//             </span>
//             <br />
//             <span ref={line2Ref} className="typewriter-line">
//               we are focused on delivering innovative solutions that help
//               businesses adapt and thrive.
//             </span>
//           </h2>
//           <p className="about-paragraph">
//             Specializing in AI-powered recruitment and property solutions, we
//             also hold patents for cutting-edge biochip and drone technologies.
//             Our expert team, consisting of PhD holders across various fields,
//             ensures that our solutions are both advanced and practical.
//           </p>
//         </div>
//         <div className="about-icon">
//           <img src={aiIcon} alt="AI Icon" className="ai-image" />
//         </div>
//       </section>
//     </>
//   );
// }

// export default Home;

import React from "react";
import Home from "./Components/Pages/Home/Home";
import { Route, Routes } from "react-router-dom";
import JobAI from "./Components/Pages/JobAI/JobAI";
import PropAI from "./Components/Pages/PropAI/PropAI";
import Contact from "./Components/Pages/Contact/Contact";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import Emerging from "./Components/Pages/Emerging/Emerging";
import NetworkStatus from "./Components/NetworkStatus/NetworkStatus";

function App() {
  return (
    <>
      <NetworkStatus />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/jobs-ai" element={<JobAI />} />
        <Route path="/prop-ai" element={<PropAI />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/emerging-tech" element={<Emerging />} />
      </Routes>
      <ScrollToTop />
    </>
  );
}

export default App;

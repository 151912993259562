import React, { useEffect } from "react";
import Navbar from "../../Navbar/Navbar";
import jobsVideoBg from "../../../Assets/jobs-section.mp4";
import featureImage1 from "../../../Assets/jobs-images/1.png";
import featureImage2 from "../../../Assets/jobs-images/2.png";
import featureImage3 from "../../../Assets/jobs-images/3.png";
import featureImage4 from "../../../Assets/jobs-images/4.png";
import featureImage5 from "../../../Assets/jobs-images/5.png";
import featureImage6 from "../../../Assets/jobs-images/6.png";
import featureImage7 from "../../../Assets/jobs-images/7.png";
import featureImage8 from "../../../Assets/jobs-images/8.png";
import featureImage9 from "../../../Assets/jobs-images/9.png";
import featureImage10 from "../../../Assets/jobs-images/10.png";
import featureImage11 from "../../../Assets/jobs-images/11.png";
import { Link } from "react-router-dom";
import "./JobAI.css";

function JobAI() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // window.scrollTo(0, 0);

    const fadeInElements = document.querySelectorAll(".key-feature-row");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      {
        threshold: 0.2, // Trigger when 20% of the element is visible
      }
    );

    fadeInElements.forEach((el) => {
      observer.observe(el);
    });

    return () => observer.disconnect(); // Cleanup observer when component unmounts
  });

  return (
    <div>
      <Navbar />
      <div className="jobs-container">
        <div className="jobs-video-background">
          <video autoPlay muted loop playsInline id="bg-video">
            <source src={jobsVideoBg} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="jobs-content-overlay">
          <h1 className="jobs-title">AI Powered Recruitment Solutions</h1>
        </div>
      </div>

      <section className="info-section">
        <p className="info-text">
          At Great Future Tech, we offer AI-powered recruitment solutions that
          streamline hiring by matching candidates to the right roles quickly
          and efficiently. Our platform automates tasks, reduces time-to-hire,
          and ensures high-quality hires, allowing your team to focus on
          strategic decisions.
        </p>
      </section>

      <section className="key-features-section">
        <h2 className="key-features-heading">Key Features</h2>

        {/* Row 1: Image Left, Text Right */}
        <div className="key-feature-row">
          <img
            src={featureImage1}
            alt="Feature 1"
            className="key-feature-image"
          />
          <div className="key-feature-text">
            <h2>Intelligent Matching Algorithms</h2>
            <p>
              Our AI-powered platform uses machine learning to evaluate
              candidate profiles based on skills, experiences, and cultural fit,
              providing highly accurate matches. It continuously improves with
              each interaction, becoming better at predicting success in
              specific roles, leading to more successful long-term hires.
            </p>
          </div>
        </div>

        {/* Row 2: Text Left, Image Right */}
        <div className="key-feature-row reverse">
          <div className="key-feature-text">
            <h2>Automated Screening and scheduling</h2>
            <p>
              Time-consuming tasks like initial candidate screening and
              interview scheduling are fully automated, allowing recruiters to
              focus on higher-value tasks. Our AI reviews CVs, conducts
              preliminary screenings, and sets up interviews, ensuring a smooth
              and efficient process from application to hire.
            </p>
          </div>
          <img
            src={featureImage2}
            alt="Feature 2"
            className="key-feature-image"
          />
        </div>

        {/* Row 3: Image Left, Text Right */}
        <div className="key-feature-row">
          <img
            src={featureImage3}
            alt="Feature 3"
            className="key-feature-image"
          />
          <div className="key-feature-text">
            <h2>Candidate Engagement Tools</h2>
            <p>
              Our AI-powered platform uses machine learning to evaluate
              candidate profiles based on skills, experiences, and cultural fit,
              providing highly accurate matches. It continuously improves with
              each interaction, becoming better at predicting success in
              specific roles, leading to more successful long-term hires.
            </p>
          </div>
        </div>

        {/* Row 4: Text Left, Image Right */}
        <div className="key-feature-row reverse">
          <div className="key-feature-text">
            <h2>OCR Technology</h2>
            <p>
              Time-consuming tasks like initial candidate screening and
              interview scheduling are fully automated, allowing recruiters to
              focus on higher-value tasks. Our AI reviews CVs, conducts
              preliminary screenings, and sets up interviews, ensuring a smooth
              and efficient process from application to hire.
            </p>
          </div>
          <img
            src={featureImage4}
            alt="Feature 4"
            className="key-feature-image"
          />
        </div>

        {/* Row 5: Image Left, Text Right */}
        <div className="key-feature-row">
          <img
            src={featureImage5}
            alt="Feature 5"
            className="key-feature-image"
          />
          <div className="key-feature-text">
            <h2>Industry-Standard CV Builder</h2>
            <p>
              To ensure fairness and consistency, our platform offers an
              industry-standard CV builder that structures candidate information
              in a uniform format. This makes it easier for recruiters to make
              informed comparisons between applicants, reducing bias and
              ensuring a level playing field.
            </p>
          </div>
        </div>

        {/* Row 6: Text Left, Image Right */}
        <div className="key-feature-row reverse">
          <div className="key-feature-text">
            <h2>AI-Powered Initial Interviews</h2>
            <p>
              To create a more inclusive recruitment process, our platform
              offers AI-powered initial interviews. These automated interviews
              ensure that all candidates, not just a pre-selected few, are given
              the opportunity to showcase their skills and qualifications. This
              approach leads to a more diverse and comprehensive pool of
              candidates, ultimately improving the overall quality of hires.
            </p>
          </div>
          <img
            src={featureImage6}
            alt="Feature 6"
            className="key-feature-image"
          />
        </div>
      </section>

      <section className="chose-us-section">
        <h2 className="chose-us-heading">Why Choose Us</h2>

        {/* First row with 3 images */}
        <div className="chose-us-row">
          <div className="chose-us-item">
            <img src={featureImage7} alt="Image 1" className="chose-us-image" />
            <p className="chose-us-text">Faster Time-to-Hire</p>
          </div>
          <div className="chose-us-item">
            <img src={featureImage8} alt="Image 2" className="chose-us-image" />
            <p className="chose-us-text">Reduced Recruitment Costs</p>
          </div>
          <div className="chose-us-item">
            <img src={featureImage9} alt="Image 3" className="chose-us-image" />
            <p className="chose-us-text">Improved Candidate Experience</p>
          </div>
          <div className="chose-us-item">
            <img
              src={featureImage10}
              alt="Image 4"
              className="chose-us-image"
            />
            <p className="chose-us-text">Enhanced Decision-Making</p>
          </div>
          <div className="chose-us-item">
            <img
              src={featureImage11}
              alt="Image 5"
              className="chose-us-image"
            />
            <p className="chose-us-text">Increased Diversity and Inclusion</p>
          </div>
        </div>
      </section>

      <footer className="footer">
        <div className="footer-content">
          <p className="company-name">Great Future Tech</p>
          <ul className="footer-links">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/jobs-ai">Jobs AI</Link>
            </li>
            <li>
              <Link to="/prop-ai">Prop AI</Link>
            </li>
            <li>
              <Link to="/emerging-tech">Emerging Tech</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
          <div className="footer-divider"></div>
          <p className="footer-copyright">
            &copy; 2024 Great Future Tech. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default JobAI;

import React, { useEffect } from "react";
import Navbar from "../../Navbar/Navbar";
import propVideoBg from "../../../Assets/propai.mp4";
import featureImage13 from "../../../Assets/prop-images/13.png";
import featureImage14 from "../../../Assets/prop-images/14.png";
import featureImage15 from "../../../Assets/prop-images/15.png";
import featureImage16 from "../../../Assets/prop-images/16.png";
import featureImage17 from "../../../Assets/prop-images/17.png";
import featureImage18 from "../../../Assets/prop-images/18.png";
import { Link } from "react-router-dom";
import "./PropAI.css";

function PropAI() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // window.scrollTo(0, 0);

    const fadeInElements = document.querySelectorAll(".key-feature-row");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible-one");
          }
        });
      },
      {
        threshold: 0.2, // Trigger when 20% of the element is visible
      }
    );

    fadeInElements.forEach((el) => {
      observer.observe(el);
    });

    return () => observer.disconnect(); // Cleanup observer when component unmounts
  });

  return (
    <div>
      <Navbar />
      <div className="jobs-container">
        <div className="prop-video-background">
          <video autoPlay muted loop playsInline id="bg-video">
            <source src={propVideoBg} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="jobs-content-overlay">
          <h1 className="jobs-title">AI for the Property Business</h1>
        </div>
      </div>

      <section className="info-section">
        <p className="info-text">
          Our AI-driven solutions are revolutionizing property management by
          automating complex processes and delivering actionable insights.
        </p>
      </section>

      <section className="key-features-section">
        <h2 className="key-features-heading">Key Features</h2>

        <div className="key-feature-row">
          <img
            src={featureImage13}
            alt="Feature 1"
            className="key-feature-image"
          />
          <div className="key-feature-text">
            <h2>Automated Property Evaluation</h2>
            <p>
              Accurately assess property values in real-time, saving time and
              improving operational efficiency.
            </p>
          </div>
        </div>

        <div className="key-feature-row reverse">
          <div className="key-feature-text">
            <h2>Predictive Market Insights</h2>
            <p>
              Leverage AI to predict market trends, helping you make informed
              decisions on investments, property sales, and pricing strategies.
            </p>
          </div>
          <img
            src={featureImage14}
            alt="Feature 2"
            className="key-feature-image"
          />
        </div>

        <div className="key-feature-row">
          <img
            src={featureImage15}
            alt="Feature 3"
            className="key-feature-image"
          />
          <div className="key-feature-text">
            <h2>Virtual Tours and Enhanced Customer Engagement</h2>
            <p>
              Offer immersive virtual property tours that increase customer
              engagement, allowing buyers and investors to explore properties
              remotely and make faster decisions.
            </p>
          </div>
        </div>

        <div className="key-feature-row reverse">
          <div className="key-feature-text">
            <h2>Simplified Ownership and Investment Management</h2>
            <p>
              Our platform allows property investments to be broken down into
              manageable units, enabling multiple investors to share ownership.
              This includes automated systems for managing ownership records and
              distributing returns.
            </p>
          </div>
          <img
            src={featureImage16}
            alt="Feature 4"
            className="key-feature-image"
          />
        </div>

        <div className="key-feature-row">
          <img
            src={featureImage17}
            alt="Feature 5"
            className="key-feature-image"
          />
          <div className="key-feature-text">
            <h2>FinTech Integration</h2>
            <p>
              We provide seamless integration with fintech solutions to
              streamline payment processing, investment transactions, and
              financial reporting. This ensures efficient handling of property
              deals and investor contributions while maintaining financial
              transparency.
            </p>
          </div>
        </div>

        <div className="key-feature-row reverse">
          <div className="key-feature-text">
            <h2>REIT Formation and Management</h2>
            <p>
              Our AI tools simplify the process of forming and managing Real
              Estate Investment Trusts (REITs). By automating compliance
              tracking, financial management, and reporting, we help you set up
              REITs with ease, enabling broader investment opportunities and
              better management of real estate portfolios.
            </p>
          </div>
          <img
            src={featureImage18}
            alt="Feature 6"
            className="key-feature-image"
          />
        </div>
      </section>

      <section className="our-team-section">
        <h2 className="our-team-heading">Our Goal</h2>
        <p className="our-team-text">
          By combining AI, fintech, and advanced property management
          technologies, we enable businesses to optimize their operations, offer
          expanded investment opportunities, and grow sustainably in the
          evolving real estate market.
        </p>
      </section>

      <footer className="footer">
        <div className="footer-content">
          <p className="company-name">Great Future Tech</p>
          <ul className="footer-links">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/jobs-ai">Jobs AI</Link>
            </li>
            <li>
              <Link to="/prop-ai">Prop AI</Link>
            </li>
            <li>
              <Link to="/emerging-tech">Emerging Tech</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
          <div className="footer-divider"></div>
          <p className="footer-copyright">
            &copy; 2024 Great Future Tech. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default PropAI;

import React, { useEffect } from "react";
import "./Emerging.css";
import Navbar from "../../Navbar/Navbar";
import iconOne from "../../../Assets/emerging-images/21.png";
import iconTwo from "../../../Assets/emerging-images/22.png";
import iconThree from "../../../Assets/emerging-images/23.png";
import iconFour from "../../../Assets/emerging-images/24.png";
import iconFive from "../../../Assets/emerging-images/25.png";
import iconSix from "../../../Assets/emerging-images/26.png";
import iconSeven from "../../../Assets/emerging-images/27.png";
import iconEight from "../../../Assets/emerging-images/28.png";
import iconNine from "../../../Assets/emerging-images/29.png";
import iconTenth from "../../../Assets/emerging-images/30.png";
import backgroundVideo from "../../../Assets/emerging-section.mp4";
import { Link, useNavigate } from "react-router-dom";

const Emerging = () => {
  const navigate = useNavigate();
  const goToContact = () => {
    navigate("/contact");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <div className="tech-container">
        <div className="emerging-home-container">
          <div className="video-background">
            <video autoPlay muted loop playsInline id="bg-video">
              <source src={backgroundVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="emerging-content-overlay">
            <h1 className="emerging-title">
              Exploring the Next Frontier in Technology.
            </h1>
            <p className="emerging-subtitle">
              Shaping the future with groundbreaking innovations in biochips,
              autonomous vehicle control, quantum computing, and cognitive
              systems.
            </p>
          </div>
        </div>

        <section className="tech-section">
          <p>
            At “Great Future Tech" we don’t just adopt emerging technologies —
            we pioneer them. We are committed to pushing the boundaries of
            what’s possible with technologies that will define the future of
            industries ranging from healthcare to transportation and computing.
          </p>
        </section>
        {/* Section 1: Microfluidic Biochips */}
        <section className="biochips">
          <h2>Microfluidic Biochips</h2>
          <p>
            Microfluidic biochips are revolutionizing the healthcare industry by
            enabling faster, more precise diagnostics and drug discovery
            processes. These tiny devices can process minute quantities of
            fluids, making them ideal for applications in diagnostics,
            personalized medicine, and biological research.
          </p>
          <h3>Key Features</h3>
          <div className="key-features">
            <div className="feature">
              <img src={iconOne} alt="Real-time diagnostics" />
              <p>Real-time diagnostics and medical testing.</p>
            </div>
            <div className="feature">
              <img src={iconTwo} alt="Advanced medical tools" />
              <p>Drug discovery and advanced medical tools for biology.</p>
            </div>
          </div>
          <h3>Current Projects</h3>
          <p>
            Partnering with healthcare providers to develop AI-enhanced biochips
            for early disease detection.
          </p>
        </section>

        {/* Section 2: Controller Circuits for Autonomous Vehicles */}
        <section className="av-circuits">
          <h2>Controller Circuits for Autonomous Vehicles (AV)</h2>
          <p>
            Our controller circuits are designed to support the next generation
            of autonomous vehicles, providing safer, more efficient control
            systems. These circuits integrate advanced sensors and AI-powered
            decision-making to ensure that AVs can navigate complex environments
            with ease.
          </p>
          <h3>Key Features</h3>
          <div className="key-features">
            <div className="feature">
              <img src={iconThree} alt="Enhanced sensor integration" />
              <p>Enhanced sensor integration for real-time navigation.</p>
            </div>
            <div className="feature">
              <img src={iconFour} alt="AI-powered navigation" />
              <p>AI-powered vehicle systems to improve AV safety.</p>
            </div>
          </div>
          <h3>Future Impact</h3>
          <p>
            Supporting the development of autonomous public transport networks
            and delivery systems.
          </p>
        </section>

        {/* New Section 1: Quantum Computing Algorithms and Circuit Design */}
        <section className="quantum-computing">
          <h2>Quantum Computing Algorithms and Circuit Design</h2>
          <p>
            Quantum computing is set to revolutionize industries by solving
            complex problems that current computers cannot handle. Our research
            focuses on developing quantum algorithms and circuits that will
            accelerate advances in cryptography, material science, and
            artificial intelligence.
          </p>
          <h3>Key Features</h3>
          <div className="key-features">
            <div className="feature">
              <img src={iconFive} alt="Quantum algorithms" />
              <p>
                Quantum algorithms for faster problem-solving in areas like
                cryptography and big data analysis.
              </p>
            </div>
            <div className="feature">
              <img src={iconSix} alt="Quantum circuit design" />
              <p>
                Quantum circuit designs that enable efficient processing on
                quantum computer.
              </p>
            </div>
          </div>
          <h3>Current Projects</h3>
          <p>
            Collaborating with academic institutions on quantum computing <br />
            applications for material discovery and financial modeling.
          </p>
        </section>

        {/* New Section 2: Cognitive Computing and Software Development */}
        <section className="cognitive-computing">
          <h2>Cognitive Computing and Software Development</h2>
          <p>
            Cognitive computing is the next step beyond deep learning, enabling
            systems that can mimic human reasoning and problem-solving. Our
            focus is on developing cognitive systems that can handle complex
            decision-making, natural language understanding, and adaptive
            learning.
          </p>
          <h3>Key Features</h3>
          <div className="key-features">
            <div className="feature">
              <img src={iconSeven} alt="NLP" />
              <p>
                Natural Language Processing (NLP) for smarter, AI-driven
                customer service systems.
              </p>
            </div>
            <div className="feature">
              <img src={iconEight} alt="Adaptive learning" />
              <p>
                Adaptive learning models that evolve based on real-time data
                inputs.
              </p>
            </div>
          </div>
          <h3>Applications</h3>
          <div className="key-features">
            <div className="feature">
              <img src={iconNine} alt="Customer solutions" />
              <p>Enhanced AI-driven customer service solutions.</p>
            </div>
            <div className="feature">
              <img src={iconTenth} alt="Accelerometer platforms" />
              <p>
                Dynamic decision-making platforms for finance, healthcare, and
                logistics industries.
              </p>
            </div>
          </div>
        </section>

        {/* Call to Action (CTA) */}
        <section className="cta-section">
          <div className="cta-button">
            <p>
              Learn More About Our Emerging Technologies and How They Can Shape
              Your Future.
            </p>
            <button
              onClick={() => {
                goToContact();
              }}
            >
              Learn More
            </button>
          </div>
        </section>

        <footer className="footer">
          <div className="footer-content">
            <p className="company-name">Great Future Tech</p>
            <ul className="footer-links">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/jobs-ai">Jobs AI</Link>
              </li>
              <li>
                <Link to="/prop-ai">Prop AI</Link>
              </li>
              <li>
                <Link to="/emerging-tech">Emerging Tech</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
            <div className="footer-divider"></div>
            <p className="footer-copyright">
              &copy; 2024 Great Future Tech. All rights reserved.
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Emerging;

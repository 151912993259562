import React, { useState, useEffect } from "react";
import "./Contact.css";
import { Link } from "react-router-dom";
import Navbar from "../../Navbar/Navbar";
import { toast } from "react-toastify";
const apiUrl = process.env.REACT_APP_API_URL;

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    inquiryType: "General",
    message: "",
    userOfficeAddress: "",
    userPhoneNumber: "",
    businessHours: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const response = await fetch(`${apiUrl}/api/contact`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(formData), // Send the form data as JSON
  //     });

  //     const result = await response.json();

  //     if (response.status === 201) {
  //       toast.success("Form submitted successfully!", {
  //         position: "top-center",
  //         autoClose: 1500,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "dark",
  //       });
  //       setFormData({
  //         name: "",
  //         email: "",
  //         phone: "",
  //         inquiryType: "General",
  //         message: "",
  //         userOfficeAddress: "",
  //         userPhoneNumber: "",
  //         businessHours: "",
  //       });
  //     } else {
  //       toast.error("Error submitting form", {
  //         position: "top-center",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "dark",
  //       });
  //     }
  //   } catch (error) {
  //     toast.error("Server error. Please try again later.", {
  //       position: "top-center",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "dark",
  //     });
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("Form submission started");

    console.log("Submitting form data:", formData); // Check the formData before sending

    try {
      const response = await fetch(`${apiUrl}/api/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      console.log("Response from server:", result); // Log the response from the server

      if (response.status === 201) {
        toast.success("Form submitted successfully!", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setFormData({
          name: "",
          email: "",
          phone: "",
          inquiryType: "General",
          message: "",
          userOfficeAddress: "",
          userPhoneNumber: "",
          businessHours: "",
        });
      } else {
        console.log("Error from server:", result);
        toast.error("Error submitting form", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error during form submission:", error); // Log the error
      toast.error("Server error. Please try again later.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <>
      <Navbar />
      <div>
        <div className="contact-wrapper">
          <div className="contact-form-section">
            <div className="contact-header">
              <h1>Get in Touch with Us</h1>
              <p>Have questions or want to collaborate? We’re here to help.</p>
            </div>
            <form className="contact-form" onSubmit={handleSubmit}>
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Phone Number:
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Type of Inquiry:
                <select
                  name="inquiryType"
                  value={formData.inquiryType}
                  onChange={handleChange}
                >
                  <option value="General">General</option>
                  <option value="Solutions">Solutions</option>
                  <option value="Emerging Technologies">
                    Emerging Technologies
                  </option>
                  <option value="Careers">Careers</option>
                </select>
              </label>
              <label>
                Message:
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </label>

              {/* Additional fields to ask user for their office information */}
              <h3>Your Contact Information:</h3>
              <label>
                Office Address:
                <input
                  type="text"
                  name="userOfficeAddress"
                  value={formData.userOfficeAddress}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Office Phone Number:
                <input
                  type="tel"
                  name="userPhoneNumber"
                  value={formData.userPhoneNumber}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Business Hours:
                <input
                  type="text"
                  name="businessHours"
                  value={formData.businessHours}
                  onChange={handleChange}
                  required
                />
              </label>

              <button type="submit">Let’s Collaborate – Reach Out Today</button>
            </form>
          </div>

          <div className="map-section">
            <h2>Our Location</h2>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2487.879877778762!2d-0.9479445116557345!3d51.42363319906835!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48768354c210d189%3A0x90507ed716fe092d!2s110%20Hilmanton%2C%20Lower%20Earley%2C%20Earley%2C%20Reading%20RG6%204HJ%2C%20UK!5e0!3m2!1sen!2sin!4v1727164659553!5m2!1sen!2sin"
              width="100%"
              height="100%"
              allowFullScreen=""
              loading="lazy"
              title="Google Map"
            ></iframe>
          </div>
        </div>

        <footer className="footer">
          <div className="footer-content">
            <p className="company-name">Great Future Tech</p>
            <ul className="footer-links">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/jobs-ai">Jobs AI</Link>
              </li>
              <li>
                <Link to="/prop-ai">Prop AI</Link>
              </li>
              <li>
                <Link to="/emerging-tech">Emerging Tech</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
            <div className="footer-divider"></div>
            <p className="footer-copyright">
              &copy; 2024 Great Future Tech. All rights reserved.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Contact;

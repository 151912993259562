import React, { useState, useEffect } from "react";
import "./ScrollToTop.css";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [useUnicodeArrow, setUseUnicodeArrow] = useState(true);

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Check if the Unicode arrow is available
  const checkUnicodeSupport = () => {
    const testElement = document.createElement("span");
    testElement.innerHTML = "⮝"; // The Unicode arrow character
    document.body.appendChild(testElement);

    // Check if the element displays correctly
    if (testElement.offsetWidth === 0 || testElement.offsetHeight === 0) {
      setUseUnicodeArrow(false); // If not displayed properly, switch to SVG
    }

    document.body.removeChild(testElement);
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    checkUnicodeSupport();
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div className="scroll-to-top">
      {isVisible && (
        <button onClick={scrollToTop} className="scroll-button">
          {/* ⮝ */}
          {useUnicodeArrow ? (
            "⮝"
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-arrow-up"
            >
              <line x1="12" y1="19" x2="12" y2="5" />
              <polyline points="5 12 12 5 19 12" />
            </svg>
          )}
        </button>
      )}
    </div>
  );
};

export default ScrollToTop;
